.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background-color: rgba(165, 150, 150, 0.459);
  }
  
  .popup-content {
    border-radius: 5px;
  }